/*
   @page table list page (merchant list)
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUIDataTable from "mui-datatables";
import moment from 'moment';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function merchant list page logic
*/
const useStyles = makeStyles(styles);

const options = {  
};
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  
  const [filterMId, setFilterMId] = useState("");
  const [filterBusinessName, setFilterBusinessName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [batchTransDailog, setBatchTransDailog] = useState(false);
  const [transData, setTransData] = useState(false);
 

  const getBatchTrans = (e, batchId, serial_number) => {
    setBatchTransDailog(true);
    e.preventDefault();
    const data = { batch_id: batchId, serial_number: serial_number };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
   
    axios.post(window.apiUrl + 'get_open_batch_trans', data, {
      headers: headers
    }).then(response => {
      console.log(response);
      var data = [];
      response.data.transData.forEach((eachTrans) => {
          
        let transTime = (eachTrans.device_created_at) ? moment(new Date(eachTrans.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY HH:mm') : "";
        data.push([transTime, eachTrans.ecrref_num, <center>{(eachTrans.edc_type) ? eachTrans.edc_type : eachTrans.payment_method}</center>, <center>{eachTrans.auth_code}</center>, <div style={{ textAlign: 'center' }}>  {(eachTrans.transaction_type == "RETURN")?"-":""}{(eachTrans.ecrref_num) ? "$" : ""}  {(eachTrans.amount) ? eachTrans.amount: ""}</div>]);
        console.log("ddddddd---**");
      });
      setTransData(data);
      console.log(data);
    }).catch(error => {     
      return error;
    });
  }
  const hideRaw = (id) =>
  {
      
    const data = { id: id };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'hide_open_batch_rec', data, {
      headers: headers
    }).then(response => {
      setTableData(false);
      getMerchantList();
    }).catch(error => {   
      return error;
    });
  }
  const doLogin = (merchantId,locationId) => {
    const data = { merchant_id: merchantId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'login_as_merchant', data, {
      headers: headers
    }).then(response => {
      if (response.data.token) {
        const currentWebUrl =  window.location.href;  
        var localHostMode = "OFF";
        if(currentWebUrl == "https://web16.magicpaypos.com/" || currentWebUrl == "https://web16.magicpaypos.com" || currentWebUrl == "https://web16.magicpaypos.com/login"  || currentWebUrl == "https://web16.magicpaypos.com/login/"){
            localHostMode = "ON"; 
        } else {
            if(currentWebUrl == "http://localhost:3000/admin/table" || currentWebUrl == "http://localhost:3000/admin/table/"){
                localHostMode = "ON";
            } else if(currentWebUrl == "https://devapp.magicpaypos.com/admin/table" || currentWebUrl == "https://devapp.magicpaypos.com/admin/table/"){
                localHostMode = "ON";
            }
            else if(currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table" || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table/"){
                localHostMode = "ON";
            }
        }
        if(response.data.same_version === "0" && localHostMode == "OFF")
        { 
         
           window.open(response.data.react_url+'login/?arg1='+btoa(localStorage.getItem('arg1'))+'&arg2='+btoa(localStorage.getItem('arg2'))+'&arg3='+btoa(merchantId), '_blank');
        }
       else
        {      
          console.log("in elseeeee");  
          localStorage.setItem('merchantToken', response.data.token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('parent_id', merchantId);
          localStorage.setItem('location_id',locationId);
          
          window.open('/dashboard', '_blank');
          
        }
  
      }
    }).catch(error => {   
      return error;
    });
  
  }
  
  /*
    @Function get merchant list
  */  
  const getMerchantList = () => {
    const data = { filterBusinessName: filterBusinessName,filterEmail:filterEmail,filterMId:filterMId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'get_open_batch', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachOpenBatch) => {
        var substr = eachOpenBatch.business_name;
        if(eachOpenBatch.business_name.length > 24)
        {
          substr = eachOpenBatch.business_name.substring(0, 24)+"..."
        }
        userArr.push([eachOpenBatch.user_id,eachOpenBatch.mid,<span class="spText"><span>{substr}</span><span class="tooltiptext">{eachOpenBatch.business_name}</span></span>,eachOpenBatch.serial_number,<span  class="spText"><span>{eachOpenBatch.nick_name}</span><span class="tooltiptext">{eachOpenBatch.nick_name}</span></span>,eachOpenBatch.version,eachOpenBatch.openBatchDays, eachOpenBatch.created_at,eachOpenBatch.last_login,"$"+eachOpenBatch.amount,<Button id="viewOpenBatchIcon" style={{ width:'100px', color: 'black', background: '#FFFFFF' }} onClick={(e) => { getBatchTrans(e, 0, eachOpenBatch.serial_number) }}><RemoveRedEyeOutlinedIcon></RemoveRedEyeOutlinedIcon></Button>,<Button id="LoginIcon" style={{ width:'100px', color: 'black', background: '#FFFFFF' }} onClick={(e) => { doLogin(eachOpenBatch.user_id,eachOpenBatch.location_id) }}><LoginOutlinedIcon></LoginOutlinedIcon></Button>,<Button onClick={(e)=>{hideRaw(eachOpenBatch.id);}}><VisibilityOffIcon></VisibilityOffIcon></Button>]);
      });     
      console.log(userArr);
      setTableData(userArr);
    }).catch(error => {     
      return error;
    });
  }

 
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
    setSuccessMsg("");
  };

  useEffect(() => {
    getMerchantList();
  }, []);

  const columns = [
    {
     name: "User ID",
     label: "ID",
     options: {
      filter: true,
      sort: false,
     }
    },
    ,
    {
     name: "Business Name",
     label: "Business Name",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "Serial Number",
     label: "serial_number",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
      name: "Amount",
      label: "amount",
      options: {
       filter: true,
       sort: false,
      }
     },
    {
      name: "Created at",
      label: "Created at",
      options: {
       filter: true,
       sort: false,
      }
     },
     
   ];

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* 
            Display error message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Open Batch List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
        <Dialog
                id="dialoggggggg"
                  open={batchTransDailog}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  style={{ padding: "0px" }}
                >
        <DialogTitle id="alert-dialog-slide-title">Batch Transactions List</DialogTitle>
        {
          (transData) ? <Table
            tableHeaderColor="primary"
            tableHead={["Trans. Time", "ECR Ref#", <center>Payment Method</center>, <center>Auth Code</center>, <center>Amount</center>]}
            tableData={transData}
          /> : <center><CircularProgress /></center>
        }

        <DialogActions>
          <Button id="btnGreyCancel"  onClick={(e) => { setBatchTransDailog(false); setTransData(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Close
          </Button>

        </DialogActions>
      </Dialog>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Open Batch List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="MID"
                        value = { filterMId }
                        onChange={(e) => {setFilterMId(e.target.value)}}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="Business Name"
                        value = {filterBusinessName}
                        onChange={(e) => { setFilterBusinessName(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="Email"
                        value = { filterEmail}
                        onChange={(e) => { setFilterEmail(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                {/* <div style={{ height: "25px",display:"none" }}>&nbsp;</div> */}
                  <FormControl style={{ width: '100%',marginTop:"-5px",display:"none" }} className={classes.popupHalfWidth} xs={12} sm={12} md={3}>
                    <Button onClick = {getMerchantList}  variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>
            <div id="divBatchRecordTable">
            <Table
              tableHeadercolor="warning"
              tableHead={["ID","MID","Business Name", "Serial #","Nickname","Version","Status", "Last Batch","Last Login","Amount","View","Login","Hide"]}
              tableData={tableData}
            />
            </div>
            {/* <MUIDataTable
              id="newdatatable"
              // title={"Employee List"}
              data={tableData}
              columns={columns}
              options={options}
            /> */}
          </CardBody>
        </Card>

      </GridItem>
    </GridContainer>
  );
}
