/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import LogDetail from "views/LogDetail/LogDetail.js";
import DeviceManager from "views/DeviceManager/DeviceManager.js";
import Notification from "views/Notification/Notification.js";
import PushData from "views/PushData/PushData.js";
import BatchMatch from "views/BatchMatch/BatchMatch.js";
import OpenBatch from "views/OpenBatch/OpenBatch.js";
import DevTools from "views/DevTools/DevTools.js";
import Logout from "views/Logout/Logout.js";
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: " ",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Add Merchant",
    rtlName: "  ",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Merchant List",
    rtlName: " ",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/device_manager",
    name: "Device Manager",
    rtlName: "",
    icon: "settings",
    component: DeviceManager,
    layout: "/admin",
  },
  {
    path: "/log",
    name: "Log Detail",
    rtlName: "",
    icon: "settings",
    component: LogDetail,
    layout: "/admin",
  },
  {
    path: "/notification",
    name: "Notification",
    rtlName: "",
    icon: NotificationImportant,
    component: Notification,
    layout: "/admin",
  },
  {
    path: "/push_data",
    name: "PushData",
    rtlName: "",
    icon: NotificationImportant,
    component: PushData,
    layout: "/admin",
  },
  {
    path: "/batchmatch",
    name: "BatchMatch",
    rtlName: "",
    icon: "content_paste",
    component: BatchMatch,
    layout: "/admin",
  },
  {
    path: "/open_batch",
    name: "OpenBatch",
    rtlName: "",
    icon: "content_paste",
    component: OpenBatch,
    layout: "/admin",
  },
  {
    path: "/dev_tools",
    name: "devTools",
    rtlName: "",
    icon: "content_paste",
    component: DevTools,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: " ",
    icon: LogoutIcon,
    component: Logout,
    layout: "/user",
  },
];

export default dashboardRoutes;
