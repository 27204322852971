/*
  @page discount report
*/

//Import require libraries
import React,{ useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import "react-datepicker/dist/react-datepicker.css";
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import RightIcon from '@material-ui/icons/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment'
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox:{
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      width:"100%",
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  tableheaders:{  
    width:"126px",
    backgroundColor: '#E5EFFF',
    border:"2px solid white",
    height:"40px"
  },
  tablebodytr:{
    height:"50px",
    textAlign:"right",
    
  },
  tablebodytrTotal:{
    backgroundColor:"#f2fcee",
    height:"50px",
    textAlign:"right"
  },
  startDateBtn :{   
    width: "100% !important",
    paddingLeft:"1%",
    '& div:nth-child(1)':{
      width: "100% !important",
    }     
  },
  buttonSearch:{
    width: "100%",
    marginLeft: "8px !important",
    backgroundColor: '#ffa726', 
    color: '#FFFFFF', 
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color:"white !important"
    }
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function discount report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [visibleDiscountData, setVisibleDiscountData] = React.useState(false);
  const [dateRange, setDateRange] = React.useState('');
  const [selectEmployee, setEmployee] = React.useState('');
  const [selectDevice, setDevice] = React.useState('');
  const [accessPage, setAccessPage] = useState(1);  
  useEffect(() => {

 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_reports === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }

  }, []);

  /*
    @Const search filtered data
  */
  const handleSearch = () =>{}
  
  /*
    @Const handle date filter
  */
  const dateChange = (event) => {
    setDateRange(event.target.value);
  };
  
  /*
    @Const handle employee filter
  */
  const employeeChange = (event) => {
    setEmployee(event.target.value);
  };
  
  /*
    @Const handle device filter
  */
  const deviceChange = (event) => {
    setDevice(event.target.value);
  };

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Reporting - Discounts</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Reporting - Discounts</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>

          {/* 
            * Filter options
          */}
          <CardBody>
          <GridContainer>
                <GridItem id="gidaterange" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Date Range</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={dateRange}
                      label="Date Range"
                      onChange={dateChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={1}>Today</MenuItem>
                      <MenuItem value={2}>Yesterday</MenuItem>
                      <MenuItem value={3}>This Week</MenuItem>
                      <MenuItem value={4}>Last Week</MenuItem>
                      <MenuItem value={5}>Last 7 Days</MenuItem>
                      <MenuItem value={6}>This Month</MenuItem>
                      <MenuItem value={7}>Last Month</MenuItem>
                      <MenuItem value={8}>Last 30 Days</MenuItem>
                      <MenuItem value={9}>Last 3 Months</MenuItem>
                      <MenuItem value={10}>This Year</MenuItem>
                      <MenuItem value={11}>Last Year</MenuItem>
                      <MenuItem value={10}>Custom Date Range</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem id="giemployee" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Employees</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectEmployee}
                      label="Employees"
                      onChange={employeeChange}
                    >
                      <MenuItem value={0}>All Employees</MenuItem>
                      <MenuItem value={1}>Employee1</MenuItem>
                      <MenuItem value={2}>Employee2</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem id="gidevice" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">Devices</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="chart"
                      value={selectDevice}
                      label="Devices"
                      onChange={deviceChange}
                    >
                      <MenuItem value={0}>All Devices</MenuItem>
                      <MenuItem value={1}>Device1</MenuItem>
                      <MenuItem value={2}>Device2</MenuItem>
                      <MenuItem value={3}>Device3</MenuItem>           
                      </Select>
                    </FormControl>
                  </GridItem>                
                </GridContainer>
              
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}} >
                    <div className={classes.startDateBtn} >
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker   
                                
                    label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                      
                    />
                    </LocalizationProvider>
                    </div>
                  </GridItem>                
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}>
                  <div className={classes.startDateBtn}>             
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker        
                    label="End Date"        
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}  style={{marginTop:"20px"}}>
                    <Button id="btnsearch" variant="contained" color="primary" onClick={handleSearch} className={`${classes.button} ${classes.buttonSearch}`}>
                      Search
                    </Button>
                  </GridItem>
                </GridContainer><br></br><br></br>
          <GridContainer>
              {/* 
                * Display discount report
              */}
              <div style={{width:"100%",height:"100px",paddingTop:"25px",backgroundColor:"#f2fcee",marginTop:"20px",display:"flex"}}>
                <div style={{width:"33%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Discounts on Orders</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$24.00</div>
                    <span style={{fontSize:"12px"}}>1 Discount applied</span>
                </div>
                <div style={{width:"33%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Discounts on Items</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$0.00</div>
                    <span style={{fontSize:"12px"}}>0 Discount applied</span>
                </div>
                <div style={{width:"33%",height:"100px",textAlign:"center"}}>
                    <div style={{width:"100%",paddingTop:"5px"}}>Total Discounts</div>
                    <div style={{width:"100%",fontWeight:"400",fontSize:"1.75em",paddingTop:"10px"}}>$24.50</div>
                    <span style={{fontSize:"12px"}}>1 Discount applied</span>
                </div>
              </div>

              <div style={{width:"100%",overflowX:"auto"}}>
                    <table style={{width:"100%",marginTop:"50px",borderCollapse:"collapse"}}>
                      <thead>
                        <tr>
                          <th rowSpan={2} className={classes.tableheaders}>Discount Name</th>
                          <th colSpan={2} className={classes.tableheaders}>Discounts on Orders</th>
                          <th colSpan={2} className={classes.tableheaders}>Discounts on Items</th>
                          <th colSpan={2} className={classes.tableheaders}>Total Discounts</th>
                          <th rowSpan={2} className={classes.tableheaders}>Employee</th>
                        </tr>
                        <tr>
                          <th className={classes.tableheaders}><div>Amount Discounted</div></th>
                          <th className={classes.tableheaders}>Number Applied</th>
                          <th className={classes.tableheaders}>Amount Discounted</th>
                          <th className={classes.tableheaders}>Number Applied</th>
                          <th className={classes.tableheaders}>Amount Discounted</th>
                          <th className={classes.tableheaders}>Number Applied</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={classes.tablebodytr}>
                          <td onClick={() => { setVisibleDiscountData(!visibleDiscountData) }} style={{paddingRight:"20px",textAlign:"left",paddingLeft:"15px",display:"flex",paddingTop:"10px"}}><div>{visibleDiscountData? <DownIcon></DownIcon>:<RightIcon></RightIcon>}</div><div>Zuri</div></td>
                          <td style={{paddingRight:"20px"}}>{visibleDiscountData? " ":"$24.50"}</td>
                          <td style={{paddingRight:"20px"}}>{visibleDiscountData? " ":"1"}</td>
                          <td style={{paddingRight:"20px"}}>{visibleDiscountData? " ":"$0.00"}</td>
                          <td style={{paddingRight:"20px"}}>{visibleDiscountData? " ":"-"}</td>
                          <td style={{paddingRight:"20px"}}>{visibleDiscountData? " ":"$24.50"}</td>
                          <td style={{paddingRight:"20px"}}>{visibleDiscountData? " ":"1"}</td>
                          <td style={{paddingRight:"20px"}}></td>
                        </tr>
                        <tr className={classes.tablebodytr} style={{display: visibleDiscountData ? 'revert' : 'none',borderBottom:"1px solid darkgray"}}>
                          <td style={{paddingRight:"20px",textAlign:"left",paddingLeft:"40px"}}>Order: Jan 19, 2022 9:03 AM</td>
                          <td style={{paddingRight:"20px"}}>$24.50</td>
                          <td style={{paddingRight:"20px"}}>1</td>
                          <td style={{paddingRight:"20px"}}>$0.00</td>
                          <td style={{paddingRight:"20px"}}>-</td>
                          <td style={{paddingRight:"20px"}}>$24.50</td>
                          <td style={{paddingRight:"20px"}}>1</td>
                          <td style={{paddingRight:"20px"}}>Rami Levi</td>
                        </tr>
                        <tr className={classes.tablebodytr} style={{display: visibleDiscountData ? 'revert' : 'none'}}>
                          <td style={{paddingRight:"20px",textAlign:"left",paddingLeft:"40px"}}>Total</td>
                          <td style={{paddingRight:"20px"}}>$24.50</td>
                          <td style={{paddingRight:"20px"}}>1</td>
                          <td style={{paddingRight:"20px"}}>$0.00</td>
                          <td style={{paddingRight:"20px"}}>-</td>
                          <td style={{paddingRight:"20px"}}>$24.50</td>
                          <td style={{paddingRight:"20px"}}>1</td>
                          <td style={{paddingRight:"20px"}}></td>
                        </tr>
                        <tr style={{borderTop:"1px solid darkgray"}}  className={classes.tablebodytrTotal}>
                          <td style={{paddingRight:"20px",textAlign:"left",paddingLeft:"20px"}}>Total</td>
                          <td style={{paddingRight:"20px"}}>$24.50</td>
                          <td style={{paddingRight:"20px"}}>1</td>
                          <td style={{paddingRight:"20px"}}>$0.00</td>
                          <td style={{paddingRight:"20px"}}>-</td>
                          <td style={{paddingRight:"20px"}}>$24.50</td>
                          <td style={{paddingRight:"20px"}}>1</td>
                          <td style={{paddingRight:"20px"}}></td>
                        </tr>
                      </tbody>
                    </table>
              </div>
          </GridContainer>
            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
