/*
  @page discount list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Helper from "./../../util/Helper";

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function discount listing page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [discountId, setDiscountId] = useState("");
  const [catDeleteDailog, setCatDeleteDailog] = useState(false);
  const [accessPage, setAccessPage] = useState(1);  
  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function For get discount list 
  */
  const getDiscountList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'get_merchant_discount', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.discountData.forEach((eachDiscount) => {
        var eachdiscountAmt = ""; 
        if (eachDiscount.discount_type == "Flat Amount") { //Check discount type was flat amount
          eachdiscountAmt = Helper.viewCurrencyFormat(parseFloat(eachDiscount.discount_amt));
        }
        else {
          eachdiscountAmt = Helper.viewPercentageFormat(parseFloat(eachDiscount.discount_amt));
        }

        userArr.push([eachDiscount.id, eachDiscount.name, eachDiscount.discount_type, eachdiscountAmt, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachDiscount.id, eachDiscount.name, eachDiscount.discount_type, eachDiscount.discount_amt)}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setDiscountId(eachDiscount.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
      });
      setTableData(userArr);

    }).catch(error => {
      return error;
    });
  }
  useEffect(() => {
 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_discount === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }
    getDiscountList();
  }, []);


  /*
    @function For edit discount
  */
  const handleEditCat = (id, name, type, amt) => {
    setDiscountId(id);
    setDiscountName(name);
    setDiscountType(type);

    if(type=="Flat Amount") //Check discount type was flat amount
    {
      var discountamt =  Helper.viewCurrencyFormat(parseFloat(amt));
    }
    else
    {
      var discountamt = amt;
    }
    setDiscountAmount(discountamt);
    setOpenAddCategory(true);
  }

  /*
    @function For delete discount
  */
  const handleDeleteDiscount = () => {
    setTableData(false);
    setCatDeleteDailog(false);
    const data = { id: discountId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'delete_merchant_discount', data, {
      headers: headers
    }).then(response => {
      getDiscountList();
      setDiscountId(0);
      if (response.data == 1) { //Check discount was deleted or not
        setSuccess(true);
        setSuccessMsg("Discount Deleted Successfully!");
      } else {
        setError(true);
        setErrorMsg("Some Error Occure!!");
      }
    }).catch(error => {
      getDiscountList();
      setDiscountId(0);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

  }

  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [discountName, setDiscountName] = React.useState("");
  const [discountType, setDiscountType] = React.useState("");
  const [discountAmount, setDiscountAmount] = React.useState("");

  /*
    @function For discount value validation 
  */
  const validateDiscValue = (e) => {
    var val = e.target.value;
    if (val.length < 6) {
      var per = val.replace(".", '');
      per = per / 100;
      if (!isNaN(per)) {
        setDiscountAmount(parseFloat(per).toFixed(2));
      }
    }
  }

  /*
    @function For save discount 
  */
  const saveDiscount = (id) => {
    setOpenAddCategory(false);
    setTableData(false);
    const data = { id: id, name: discountName, type: discountType, amt: discountAmount };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'add_merchant_discount', data, {
      headers: headers
    }).then(response => {
      setDiscountId(0);
      setDiscountName("");
      getDiscountList();
      if (id == 0) { //Check if id was 0
        setSuccess(true);
        setSuccessMsg("Discount Added Successfully!");
      }
      else {
        setSuccess(true);
        setSuccessMsg("Discount Updated Successfully!");
      }
    }).catch(error => {
      getDiscountList();
      setDiscountId(0);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });
  }

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Discounts List</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check id data not come then display loading dialog.
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Discounts List</h4>
              <p className={classes.cardCategoryWhite}>
                {/* Here is a subtitle for this table */}
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Discounts...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>

        {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

        {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Discount List</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { handleEditCat(0, "", false, ""); setOpenAddCategory(true) }}
            >+</Button>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Type", "Amount", "Edit", "Delete"]}
              tableData={tableData}
            />
          </CardBody>
        </Card>
        {/* 
          * Add discount form start 
        */}
        <Dialog open={openAddCategory} onClose={() => { setOpenAddCategory(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(discountId == 0) ? "Add New " : "Update "}Discount</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Discount Name"
              onChange={(e) => setDiscountName(e.target.value.replace(/[^a-z1-9 ]/gi, ''))}
              value={discountName}
              fullWidth
            />

            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>

            
              <InputLabel id="demo-simple-select-label">Discount Type</InputLabel>
              <Select
                style={{width: '100%'}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setDiscountType(e.target.value)}
                value={discountType}
              >
                <MenuItem value={"Percentage"}>Percentage</MenuItem>
                <MenuItem value={"Flat Amount"}>Flat Amount</MenuItem>
              </Select>
           

            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <TextField
              autoFocus
              margin="dense"
              id="discountAmtInput"
              label="Discount Amount"
              onChange={(e) => (discountType == "Flat Amount") ? setDiscountAmount(Helper.currencyFormatWithDot(e, 'discountAmtInput')) : validateDiscValue(e)}
              value={discountAmount}
              fullWidth
              inputProps={{ min: 0, style: { textAlign: 'right' } }}
            />
          </GridItem>
          </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setDiscountId(0); setDiscountName(""); setOpenAddCategory(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveDiscount(discountId, discountName) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(discountId == 0) ? "Add " : "Update"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          * Delete ctegory  popup start
        */}
        <Dialog
          open={catDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete Discount?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setDiscountId(0); setCatDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteDiscount} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
