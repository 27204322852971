/*
  @page sales overview report
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment'
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';



var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;


  /*
    @Const generate email subscription chart
  */
  const emailsSubscriptionChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      
    ],
    series: [
      [{ meta: "value is:", value: 500 }, 
      { meta: "value is:", value: 200 },
      { meta: "value is:", value: 600 }, 
      { meta: "value is:", value: 400 }, 
      { meta: "value is:", value: 1600 }, 
      { meta: "value is:", value: 300 }, 
      { meta: "value is:", value: 700 }, 
      { meta: "value is:", value: 800 }, 
      { meta: "value is:", value: 900 }, 
      { meta: "value is:", value: 500 }, 
      { meta: "value is:", value: 500 }, 
      { meta: "value is:", value: 800 }],
    ]
  },
  options: {
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
    },
    low: 0,
    high: 5,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
    height:400,    
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ]
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
            data.element.attr({
               style: 'stroke: #235586; fill:#235586; stroke-width: 30px;'
            });        
      }
    },
    
  },
};

  /*
    @Const generate sales summary chart
  */
const chartSalesSummary = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      
    ],
    series: [
      [{ meta: "value is:", value: 500 }, 
      { meta: "value is:", value: 200 },
      { meta: "value is:", value: 600 }, 
      { meta: "value is:", value: 400 }, 
      { meta: "value is:", value: 1000 }],
      [{ meta: "value is:", value: 200 }, 
      { meta: "value is:", value: 400 },
      { meta: "value is:", value: 800 }, 
      { meta: "value is:", value: 500 }, 
      { meta: "value is:", value: 600 }],
    ]
  },
  options: {
    seriesBarDistance: 40,
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },  
    height:300,
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ]
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
            data.element.attr({
               style: 'stroke: #235586; fill:#235586; stroke-width: 20px;'
            });        
      }
    },
    
  },
};


  /*
    @Const generate dual data chart
  */
const dualDataChart = {
  data: {
    labels: [
      "Credit Card",
      "Debit Card",
      "Cash",
    ],
    series: [
      [550, 400, 300],
      [300, 200, 900]
    ]
  },
  options: {
    seriesBarDistance: 40,
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },  
    height:300,
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ]
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
            data.element.attr({
               style: 'stroke: #235586; fill:#235586; stroke-width: 20px;'
            });        
      }
    },
    
  },
};


  /*
    @Const generate top 5 card type chart
  */
const top5CardTypeChart = {
  data: {
    labels: [
      "Credit Card",
      "Debit Card",
      "Cash",
    ],
    series: [
      [100, 900, 500],
      [1000, 500, 700]
    ]
  },
  options: {
    seriesBarDistance: 40,
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },  
    height:300,
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ]
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
            data.element.attr({
               style: 'stroke: #235586; fill:#235586; stroke-width: 20px;'
            });        
      }
    },
    
  },
};

  /*
    @Const generate top 5 categorys chart
  */
const top5CategoryChart = {
  data: {
    labels: [
      "Entrees Meat",
      "Appetizers",
      "Skewers",
      "Salads",
      "Entrees Chicken",
    ],
    series: [
      [100, 900, 500,800,300],
      [1000, 500, 700,100,500],
    ]
  },
  options: {
    reverseData: true,
    horizontalBars: true,
    seriesBarDistance: 25,
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
      offset: 70,
    },
    low: 0,
    high: 1000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },  
    height:300,
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ]
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
            data.element.attr({
               style: 'stroke: #235586; fill:#235586; stroke-width: 20px;'
            });        
      }
    },
    
  },
};

  /*
    @Const generate top 5 items chart
  */
const top5ItemChart = {
  data: {
    labels: [
      "Custom Item",
      "Rib Steak 16 Oz On The Bone",
      "Skewer",
      "JZ Steak 32 Oz Ribeye",
      "Ribeye 12Oz",
    ],
    series: [
      [100, 200, 500,400,300],
      [300, 400, 100,500,200],
    ]
  },
  options: {
    reverseData: true,
    horizontalBars: true,
    seriesBarDistance: 25,
    axisX: {
      showGrid: true,
    },
    axisY: {
      showGrid: true,
      offset: 100,
    },
    low: 0,
    high: 500,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },  
    height:400,
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ]
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }

      if (data.type === "bar") {
            data.element.attr({
               style: 'stroke: #235586; fill:#235586; stroke-width: 20px;'
            });        
      }
    },
    
  },
};

/*
  @const Page styles
*/
const styles = {  
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    width:"100%",
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  reportEachRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
    width: '97%',
    paddingLeft:'3%',
  },
  reportEachRowRightSide: {
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'right',
    width: '97%',
    paddingRight:'3%',
  },
  reportEachRowScrollable: {
    float: 'left',
    width: '125px',
  },
  reportEachRowScrollableOne:{
    width:'100% !important',
  },
  reportEachRowScrollableTwo:{
    width:'50% !important',
  },
  reportEachRowScrollableThree:{
    width:'33% !important',
  },
  reportEachRowScrollableFour:{
    width:'25%',
  },
  reportEachRowScrollableFive:{
    width:'20% !important',
  },
  reportEachRowScrollableSix:{
    width:'16.66% !important',
  },
  reportEachRowScrollableSeven:{
    width:'14.28% !important',
  },
  reportEachRowScrollableEight:{
    width:'12.5% !important',
  },
  rowBackgroundColorOne: {
    backgroundColor: "#f2f2f2"
  },
  reportRowLeft:{
    textAlign:'left',
  },
  reportRowRight:{
    textAlign:'right',
  },
  classTextRight:{
    textAlign:'right'
  },
  fontWeightBold:{
    fontWeight:'bold',
    fontSize:'1.125em',
  },
  fontBlueColor:{
    color:'#3271ab'
  },
  borderTopLine:{
    borderTop:"1px solid #CACACA"
  },
  borderBottomLine:{
    borderBottom:"1px solid #CACACA"
  },
  trheader:{
    backgroundColor:"#dee0e1",
    height:"40px",
    color:"rgba(52,65,70,.8)",
    fontWeight:"none",
    textAlign:"right"
  },
  trheadertd:{
    border:"1px solid white",
    paddingLeft:"10px",
    paddingRight:"10px"
  },
  trodd:{
    backgroundColor:"white",
    height:"40px",
    textAlign:"right"
  },
  treven:{
    backgroundColor:"rgba(118,136,140,.06)",
    height:"40px",
    textAlign:"right"
  },
  trtd:{
    paddingLeft:"10px",
    paddingRight:"10px"
  },
  startDateBtn :{   
    width: "100% !important",
    paddingLeft:"1%",
    '& div:nth-child(1)':{
      width: "100% !important",
    }     
  },
  buttonSearch:{
    width: "100%",
    marginLeft: "8px !important",
    backgroundColor: '#ffa726', 
    color: '#FFFFFF', 
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color:"white !important"
    }
  },
  
};

  
/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function sales overview report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [visible, setVisible] = React.useState(true);
  const [visibleSummary, setVisibleSummary] = React.useState(false);
  const [weeklyBtnColor, setTrendsBtnColor] = React.useState(true);
  const [summaryBtnColor, setFullReportBtnColor] = React.useState(false);
  const [dateRange, setDateRange] = React.useState('');
  const [selectEmployee, setEmployee] = React.useState('');
  const [selectDevice, setDevice] = React.useState('');
  const [hoverOne, sethoverOne] = useState(false);
  const [hoverTwo, sethoverTwo] = useState(false);
  const [hoverThree, sethoverThree] = useState(false);
  const [hoverFour, sethoverFour] = useState(false);
  const [hoverFive, sethoverFive] = useState(false);
  const [visibleTrends, setVisibleTrends] = React.useState(true);
  const [visibleFullReport, setVisibleFullReport] = React.useState(false);
  const [accessPage, setAccessPage] = useState(1);
  const [reportStartDate, setReportStartDate] = React.useState();
  useEffect(() => {    

 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_reports === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }	

  }, []);

  /*
    @Const search filtered data
  */
  const handleSearch = () => { 
    startDate.setDate(startDate.getDate() + 1);    
    endDate.setDate(endDate.getDate() + 1);        
    const data = {'start_date':startDate.toISOString().slice(0, 10)+" 00:00:00",'end_date':endDate.toISOString().slice(0, 10)+" 23:59:59"};
    startDate.setDate(startDate.getDate() - 1);    
    endDate.setDate(endDate.getDate() - 1); 
    getReportData(data);
  }

  /*
    @Const filter using date
  */
    const dateChange = (event) => {   
      setDateRange(event.target.value);
    };
  
  /*
    @Const filter using employee
  */
  const employeeChange = (event) => {
    setEmployee(event.target.value);
  };
  
  /*
    @Const filter using device
  */
  const deviceChange = (event) => {
    setDevice(event.target.value);
  };

  const [grossTotal, setGrossTotal] = React.useState(0.00);
  const [discountTotal, setDiscountTotal] = React.useState(0.00);
  const [refundTotal, setRefundTotal] = React.useState(0.00);
  const [taxesTotal, setTaxesTotal] = React.useState(0.00);
  const [tipTotal, setTipTotal] = React.useState(0.00);
  const [serviceChargesTotal, setServiceChargesTotal] = React.useState(0.00);
  const [collectedAmtTotal, setCollectedAmtTotal] = React.useState(0.00);
  const [netSaleTotal, setNetSaleTotal] = React.useState(0.00);
  const [totalOrders, setTotalOrder] = React.useState(0);  
  const [salesType, setSalesType] = React.useState('');
  const [showData, setShowData] = React.useState('');
  const [chartData, setChartData] = React.useState({data:[],options:{axisX:{showGrid: true,},axisY: {showGrid: true, },low: 0,high:5,chartPadding: {top: 0,right: 5,bottom: 0,left: 0,},height:400,plugins: [ChartistTooltip({appendToBody: true})]}});
   

  /*
    @Const get sales overview report data
  */
  const getReportData = (data) => {
      const headers = {
        'Authorization': 'Bearer '+localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'merchant_report_sales_datewise', data,{
      headers: headers
    }).then(response => {          
          setTableData(response.data.tableData);              
          setGrossTotal(response.data.grossSalesTotal);
          setDiscountTotal(response.data.discountTotal);
          setRefundTotal(response.data.refundTotal);          
          setTaxesTotal(response.data.taxesTotal);
          setTipTotal(response.data.tipTotal);
          setServiceChargesTotal(response.data.serviceChargesTotal);
          setCollectedAmtTotal(response.data.collectedAmtTotal);
          setNetSaleTotal(response.data.netSaleTotal);
          setTotalOrder(response.data.totalOrder);
          setSalesType(response.data.salesType);
          setShowData(response.data.dateRange);

          var chartLabel = [];
          var chartLabelValue = [];
          var max = 0;
          response.data.tableData.map((eachRow,id)=>{          
            let label = eachRow.date.split(", ");            
            chartLabel.push(label[1]);
            chartLabelValue.push({ meta: "value is:", value: eachRow.net_sale });
            (max<eachRow.net_sale)?max=eachRow.net_sale:"";
          });
          var chart = {};               
          emailsSubscriptionChart.options.high = 20; 
          chart["data"] = {labels:chartLabel,series:[chartLabelValue]};
          chart["options"] ={
            axisX: {
              showGrid: true,
            },
            axisY: {
              showGrid: true,
            },
            low: 0,
            high: max,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
            height:400,    
            plugins: [
              ChartistTooltip({
                appendToBody: true
              })
            ]
          };  
          setChartData(chart);  
        }).catch(error => {                  
          return error;
        });
  }
  var widthSetCol = "125px";
  var widthSetColMinWidth = "125px";
  var widthSetScroll = "scroll";
  var scrollAreaWidth = "max-content";

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Reporting - Sales Overview</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if(tableData.length<6){ //Check if tabledata length smaller than 6 than set column width 100% / 6
       widthSetCol = (100 / tableData.length)+"%";
        scrollAreaWidth = "100%";
        widthSetColMinWidth = "125px";
        widthSetScroll = "auto";  
   }
  return (

    <GridContainer>
     
        <div style={{width:"100%"}}>
          <Card>
          <CardHeader className="cardheader" color="warning">
            <h4 className={classes.cardTitleWhite}>Reporting - Sales Overview</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
            {/* 
              * Filter options
            */}
            <CardBody>
            <GridContainer>
            <GridItem id="gidaterange" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Date Range</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={dateRange}
                  label="Date Range"
                  onChange={dateChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Yesterday</MenuItem>
                  <MenuItem value={3}>This Week</MenuItem>
                  <MenuItem value={4}>Last Week</MenuItem>
                  <MenuItem value={5}>Last 7 Days</MenuItem>
                  <MenuItem value={6}>This Month</MenuItem>
                  <MenuItem value={7}>Last Month</MenuItem>
                  <MenuItem value={8}>Last 30 Days</MenuItem>
                  <MenuItem value={9}>Last 3 Months</MenuItem>
                  <MenuItem value={10}>This Year</MenuItem>
                  <MenuItem value={11}>Last Year</MenuItem>
                  <MenuItem value={10}>Custom Date Range</MenuItem>
                </Select>
              </FormControl>
            </GridItem>

            

            <GridItem id="giemployee" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Employees</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={selectEmployee}
                  label="Employees"
                  onChange={employeeChange}
                >
                  <MenuItem value={0}>All Employees</MenuItem>
                  <MenuItem value={1}>Employee1</MenuItem>
                  <MenuItem value={2}>Employee2</MenuItem>
                
                  
                </Select>
              </FormControl>
            </GridItem>
            <GridItem id="gidevice" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Devices</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="chart"
                  value={selectDevice}
                  label="Devices"
                  onChange={deviceChange}
                >
                  <MenuItem value={0}>All Devices</MenuItem>
                  <MenuItem value={1}>Device1</MenuItem>
                  <MenuItem value={2}>Device2</MenuItem>
                  <MenuItem value={3}>Device3</MenuItem>
                                
                  </Select>
                </FormControl>
              </GridItem>                
            </GridContainer>
              
              <GridContainer>
              <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"12px"}} >
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker   
                                
                    label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                      
                    />
                    </LocalizationProvider>
                    </FormControl>
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"12px"}}>
                  <FormControl sx={{ m: 1, width: "100%" }}>            
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker        
                    label="End Date"        
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}  style={{marginTop:"20px"}}>
                    <Button id="btnsearch" variant="contained" color="primary" onClick={handleSearch} className={`${classes.button} ${classes.buttonSearch}`}>
                      Search
                    </Button>
                  </GridItem>
            </GridContainer><br></br><br></br>

              <div style={{ width: "100%", float: "right", textAlign:"right" }}>
                <div style={{display:"flex",float:"right"}}>
                  <Button onClick={() => {setVisibleTrends(true),setVisibleFullReport(false), setTrendsBtnColor(true); setFullReportBtnColor(false); }} id="btnWeeklySales" variant="contained" color="primary" style={{ marginTop: '25px', backgroundColor: weeklyBtnColor ? '#ffa726' : '#FFFFFF', color: weeklyBtnColor ? '#FFFFFF' : '#ffa726', border: weeklyBtnColor ? '1px solid #ffa726' : '1px solid #ffa726', boxShadow: "none", borderRadius: "0px" }} className={classes.button}>
                    TRENDS
                  </Button>
                  <Button onClick={() => {setVisibleTrends(false),setVisibleFullReport(true),setTrendsBtnColor(false); setFullReportBtnColor(true); }} variant="contained" color="primary" style={{ marginTop: '25px', marginRight: "10px", backgroundColor: summaryBtnColor ? '#ffa726' : '#FFFFFF', color: summaryBtnColor ? '#FFFFFF' : '#ffa726', border: summaryBtnColor ? '1px solid #ffa726' : '1px solid #ffa726', boxShadow: "none", borderRadius: "0px" }} className={classes.button}>
                    FULL REPORT
                  </Button>
                </div>
              </div>

            </CardBody>
          </Card>
        </div>        

        <div style={{width:"100%",display: visibleTrends ? 'block' : 'none'}} >

          <div style={{width:"100%"}}>

          <Card>
              <CardBody>

              <div style={{width:"100%",display:"flex"}}>
                    <div class="chartSalesSummary" style={{width:"60%"}}>
                        <ChartistGraph
                        className="ct-chart"
                        data={chartSalesSummary.data}
                        type="Bar"
                        options={chartSalesSummary.options}
                        responsiveOptions={chartSalesSummary.responsiveOptions}
                        listener={chartSalesSummary.animation}
                      />
                    </div>
                    <div style={{width:"40%",paddingLeft:"30px"}}>
                        <div  onMouseEnter={() => sethoverOne(true)}
                              onMouseLeave={() => sethoverOne(false)} 
                              style={{ backgroundColor: hoverOne ? '#e6f3ff' : '#FFFFFF', width:"100%",height:"60px",paddingLeft:"10px"}}>
                          <div style={{width:"50%",float:"left",textAlign:"left",fontSize:"20px",lineHeight:"60px",textAlign:"left"}}>Orders</div>
                          <div style={{width:"35%",float:"right",textAlign:"right",paddingRight:"10px"}}>
                              <div style={{fontSize:"15px",lineHeight:"30px",textAlign:"right"}}><b>8</b></div>
                              <div style={{display:"flex",textAlign:"right",float:"right"}}>
                              <div>
                                <ArrowDropDownIcon></ArrowDropDownIcon> 
                              </div>
                              <div>
                              12.16%(74)
                              </div>
                              </div>
                          </div>
                          
                        </div>
                        <div  onMouseEnter={() => sethoverTwo(true)}
                              onMouseLeave={() => sethoverTwo(false)} 
                              style={{ backgroundColor: hoverTwo ? '#e6f3ff' : '#FFFFFF',width:"100%",height:"60px",paddingLeft:"10px"}}>
                          <div style={{width:"50%",float:"left",textAlign:"left",fontSize:"20px",lineHeight:"60px",textAlign:"left"}}>Gross Sales</div>
                          <div style={{width:"35%",float:"right",textAlign:"right",paddingRight:"10px"}}>
                              <div style={{fontSize:"15px",lineHeight:"30px",textAlign:"right"}}><b>$18,013.55</b></div>
                              <div style={{display:"flex",textAlign:"right",float:"right"}}>
                              <div>
                                <ArrowDropDownIcon></ArrowDropDownIcon> 
                              </div>
                              <div>
                              12.16%(74)
                              </div>
                              </div>
                          </div>
                        </div>
                        <div onMouseEnter={() => sethoverThree(true)}
                              onMouseLeave={() => sethoverThree(false)} 
                              style={{backgroundColor: hoverThree ? '#e6f3ff' : '#FFFFFF',width:"100%",height:"60px",paddingLeft:"10px"}}>
                          <div style={{width:"50%",float:"left",textAlign:"left",fontSize:"20px",lineHeight:"60px",textAlign:"left"}}>Net Sales</div>
                          <div style={{width:"35%",float:"right",textAlign:"right",paddingRight:"10px"}}>
                              <div style={{fontSize:"15px",lineHeight:"30px",textAlign:"right"}}><b>$18,013.55</b></div>
                              <div style={{display:"flex",textAlign:"right",float:"right"}}>
                              <div>
                                <ArrowDropDownIcon></ArrowDropDownIcon> 
                              </div>
                              <div>
                              12.16%(74)
                              </div>
                              </div>
                          </div>
                        </div>
                        <div onMouseEnter={() => sethoverFour(true)}
                              onMouseLeave={() => sethoverFour(false)} 
                              style={{backgroundColor: hoverFour ? '#e6f3ff' : '#FFFFFF',width:"100%",height:"60px",paddingLeft:"10px"}}>
                          <div style={{width:"50%",float:"left",textAlign:"left",fontSize:"20px",lineHeight:"60px",textAlign:"left"}}>Average Ticket Size</div>
                          <div style={{width:"35%",float:"right",textAlign:"right",paddingRight:"10px"}}>
                              <div style={{fontSize:"15px",lineHeight:"30px",textAlign:"right"}}><b>$217.03</b></div>
                              <div style={{display:"flex",textAlign:"right",float:"right"}}>
                              <div>
                                <ArrowDropDownIcon></ArrowDropDownIcon> 
                              </div>
                              <div>
                              12.16%(74)
                              </div>
                              </div>
                          </div>
                        </div>
                        <div onMouseEnter={() => sethoverFive(true)}
                              onMouseLeave={() => sethoverFive(false)}  
                              style={{backgroundColor: hoverFive ? '#e6f3ff' : '#FFFFFF',width:"100%",height:"60px",paddingLeft:"10px"}}>
                          <div style={{width:"50%",float:"left",textAlign:"left",fontSize:"20px",lineHeight:"60px",textAlign:"left"}}>Amount Collected</div>
                          <div style={{width:"35%",float:"right",textAlign:"right",paddingRight:"10px"}}>
                              <div style={{fontSize:"15px",lineHeight:"30px",textAlign:"right"}}><b>$22,465.64</b></div>
                              <div style={{display:"flex",textAlign:"right",float:"right"}}>
                              <div>
                                <ArrowDropDownIcon></ArrowDropDownIcon> 
                              </div>
                              <div>
                              12.16%(74)
                              </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>

              </CardBody>
            </Card>

          </div>


            <div style={{width:"100%",display:"flex"}}>
              <Card style={{padding:"10px",width:"50%",marginRight:"5px"}}>
                <div style={{fontSize:"1.6em",width:"100%",paddingLeft:"20px",paddingBottom:"20px",paddingTop:"20px"}}>
                Top 5 Tender Types
                </div>
                <div style={{width:"100%",paddingLeft:"20px"}}>
                  <span><b>Amount Collected:  </b></span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"brown",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 20 </span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"#235586",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 27 </span>
                </div>
                <CardBody>
                  
                      {/* 
                        * Display top 5 tender chart
                      */}
                      <div class="top5tenderchart" style={{width:"100%"}}>
                          <ChartistGraph
                          className="ct-chart"
                          data={dualDataChart.data}
                          type="Bar"
                          options={dualDataChart.options}
                          responsiveOptions={dualDataChart.responsiveOptions}
                          listener={dualDataChart.animation}
                        />
                      </div>

                </CardBody>
              </Card>
              <Card style={{padding:"10px",width:"50%",marginLeft:"5px"}}>
                <div style={{fontSize:"1.6em",width:"100%",paddingLeft:"20px",paddingBottom:"20px",paddingTop:"20px"}}>
                Top 5 Revenue Classes
                </div>
                <div style={{width:"100%",paddingLeft:"20px"}}>
                  <span><b>Amount Collected:  </b></span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"brown",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 20 </span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"#235586",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 27 </span>
                </div>
                <CardBody>
                  
                      {/* 
                        * Display top 5 revenue chart
                      */}
                      <div class="top5revenuechart" style={{width:"100%"}}>
                          <ChartistGraph
                          className="ct-chart"
                          data={dualDataChart.data}
                          type="Bar"
                          options={dualDataChart.options}
                          responsiveOptions={dualDataChart.responsiveOptions}
                          listener={dualDataChart.animation}
                        />
                      </div>

                </CardBody>
              </Card>
            </div>

            <div style={{width:"100%",display:"flex"}}>
              <Card style={{padding:"10px",width:"50%",marginRight:"5px"}}>
                <div style={{fontSize:"1.6em",width:"100%",paddingLeft:"20px",paddingBottom:"20px",paddingTop:"20px"}}>
                Top 5 Card Types
                </div>
                <div style={{width:"100%",paddingLeft:"20px"}}>
                  <span><b>Amount Collected:  </b></span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"brown",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 20 </span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"#235586",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 27 </span>
                </div>
                <CardBody>
                  
                      {/* 
                        * Display top 5 card type chart
                      */}
                      <div class="top5cardtypechart" style={{width:"100%"}}>
                          <ChartistGraph
                          className="ct-chart"
                          data={top5CardTypeChart.data}
                          type="Bar"
                          options={top5CardTypeChart.options}
                          responsiveOptions={top5CardTypeChart.responsiveOptions}
                          listener={top5CardTypeChart.animation}
                        />
                      </div>

                </CardBody>
              </Card>
              <Card style={{padding:"10px",width:"50%",marginLeft:"5px"}}>
                <div style={{fontSize:"1.6em",width:"100%",paddingLeft:"20px",paddingBottom:"20px",paddingTop:"20px"}}>
                Top 5 Categories
                </div>
                <div style={{width:"100%",paddingLeft:"20px"}}>
                  <span><b>Amount Collected:  </b></span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"brown",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 20 </span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"#235586",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 27 </span>
                </div>
                <CardBody>
                  
                      
                      {/* 
                        * Display top 5 category chart
                      */}
                      <div class="top5categorycart" style={{width:"100%"}}>
                          <ChartistGraph
                          className="ct-chart"
                          data={top5CategoryChart.data}
                          type="Bar"
                          options={top5CategoryChart.options}
                          responsiveOptions={top5CategoryChart.responsiveOptions}
                          listener={top5CategoryChart.animation}
                        />
                      </div>

                </CardBody>
              </Card>
            </div>

            <div style={{width:"100%",display:"flex"}}>
              <Card style={{padding:"10px",width:"60%",marginRight:"5px"}}>
                <div style={{fontSize:"1.6em",width:"100%",paddingLeft:"20px",paddingBottom:"20px",paddingTop:"20px"}}>
                Top 5 Items
                </div>
                <div style={{width:"100%",paddingLeft:"20px"}}>
                  <span><b>Net Sales:  </b></span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"brown",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 20 </span>
                  <span style={{height:"10px",width:"10px",backgroundColor:"#235586",borderRadius:"50%",display:"inline-block"}}></span>
                  <span> Mar 27 </span>
                </div>
                <CardBody>
                  
                      {/* 
                        * Display top 5 items chart
                      */}
                      <div class="top5itemchart" style={{width:"100%"}}>
                          <ChartistGraph
                          className="ct-chart"
                          data={top5ItemChart.data}
                          type="Bar"
                          options={top5ItemChart.options}
                          responsiveOptions={top5ItemChart.responsiveOptions}
                          listener={top5ItemChart.animation}
                        />
                      </div>

                </CardBody>
              </Card>
            </div>

        </div>

        <div style={{width:"100%",display: visibleFullReport ? 'block' : 'none'}}>

          <div style={{width:"100%"}}>
            <Card>
              <CardBody>
              <GridContainer>
                {/* 
                  * Display sales full report
                */}
              <div style={{fontSize:"30px",marginTop:"20px",marginLeft:"20px"}}>Sales</div>
            <div className="divSummary" style={{ display:"flex", width: "100%",marginTop:"30px"}}>
              <table style={{ width: "100%",borderCollapse:"collapse" }} className={classes.tableSummary}>
                <tr>
                  <td style={{ width: "50%", textAlign: "left", paddingLeft:"50px", backgroundColor: "#f2f2f2", padding: "8px" }}><b>Gross Sales</b></td>
                  <td style={{ width: "50%", textAlign: "right",paddingRight:"30px", backgroundColor: "#f2f2f2", padding: "8px",color:"#3271ab" }}><b>$72,014.44</b></td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", padding: "8px" }}>Discounts</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", padding: "8px" }}>-$65.77</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", backgroundColor: "#f2f2f2", padding: "8px" }}>Refunds</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", backgroundColor: "#f2f2f2", padding: "8px",}}>-$154.80</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", padding: "8px" }}><b>Net Sales</b></td>
                  <td style={{ textAlign: "right",paddingRight:"30px", padding: "8px",color:"#3271ab" }}><b>$71,793.87</b></td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", backgroundColor: "#f2f2f2", padding: "8px" }}>Non-revenue Items</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", backgroundColor: "#f2f2f2", padding: "8px" }}>$0.00</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", padding: "8px" }}>Gift Card Activations</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", padding: "8px" }}>$0.00</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", backgroundColor: "#f2f2f2", padding: "8px" }}>Taxes & Fees</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", backgroundColor: "#f2f2f2", padding: "8px" }}>$4,947.77</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", padding: "8px" }}>Service Charges</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", padding: "8px" }}>$11,482.55</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", paddingLeft:"30px", padding: "8px", backgroundColor: "#f2f2f2" }}>Tips</td>
                  <td style={{ textAlign: "right",paddingRight:"30px", padding: "8px" , backgroundColor: "#f2f2f2"}}>$1,060.24</td>
                </tr>
                <tr style={{borderTop:"1px solid gray"}}>
                  <td style={{ textAlign: "left", paddingLeft:"30px", padding: "8px" }}><b>Amount Collected</b></td>
                  <td style={{ textAlign: "right",paddingRight:"30px", padding: "8px" }}><b>$89,284.43</b></td>
                </tr>
               
              </table>
            </div>
          </GridContainer>
              </CardBody>
            </Card>
          </div>

          <div style={{width:"100%"}}>
            <Card>
              <CardBody>
              <GridContainer>
                {/* 
                  * Display tender type full report
                */}
              <div style={{fontSize:"30px",marginTop:"20px",marginLeft:"20px"}}>Tender Types</div>
            <div className="divSummary" style={{ display:"flex", width: "100%",marginTop:"30px"}}>
              <table style={{width:"100%",borderCollapse:"collapse"}}>
                <thead>
                  <tr className={classes.trheader}>
                    <td className={classes.trheadertd} style={{textAlign:"left"}}>Tender Types</td>
                    <td className={classes.trheadertd}>Sales Total</td>
                    <td className={classes.trheadertd}>Refund Total</td>
                    <td className={classes.trheadertd}>Amount Collected</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.trodd}>
                    <td className={classes.trtd} style={{textAlign:"left"}}>Credit Card</td>
                    <td className={classes.trtd}>$61,946.70</td>
                    <td className={classes.trtd}>-$193.50</td>
                    <td className={classes.trtd}>$61,753.20</td>
                  </tr>
                  <tr className={classes.treven}>
                    <td className={classes.trtd} style={{textAlign:"left"}}>Debit Card</td>
                    <td className={classes.trtd}>$61,946.70</td>
                    <td className={classes.trtd}>$0.00</td>
                    <td className={classes.trtd}>$61,753.20</td>
                  </tr>
                  <tr className={classes.trodd}>
                    <td className={classes.trtd} style={{textAlign:"left"}}>Cash</td>
                    <td className={classes.trtd}>$61,946.70</td>
                    <td className={classes.trtd}>$0.00</td>
                    <td className={classes.trtd}>$61,753.20</td>
                  </tr>
                  <tr className={classes.treven}>
                    <td className={classes.trtd} style={{textAlign:"left"}}>Gift Cards</td>
                    <td className={classes.trtd}>$61,946.70</td>
                    <td className={classes.trtd}>$0.00</td>
                    <td className={classes.trtd}>$61,753.20</td>
                  </tr>
                  <tr className={classes.trodd} style={{borderTop:"1px solid gray"}}>
                    <td className={classes.trtd} style={{textAlign:"left"}}><b>Total</b></td>
                    <td className={classes.trtd}><b>$89,477.93</b></td>
                    <td className={classes.trtd}><b>-$193.50</b></td>
                    <td className={classes.trtd}><b>$89,284.43</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </GridContainer>
              </CardBody>
            </Card>
          </div>
{/* 
          <div style={{width:"100%"}}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <div style={{fontSize:"30px",marginTop:"20px",marginLeft:"20px"}}>Revenue Classes</div>
                  </GridContainer>
                </CardBody>
              </Card>
          </div> */}

          <div style={{width:"100%"}}>
            <Card>
              <CardBody>
                <GridContainer>
                  {/* 
                    * Display card type full report
                  */}
                  <div style={{fontSize:"30px",marginTop:"20px",marginLeft:"20px"}}>Sales By Card Type</div>
                    <div className="divSummary" style={{ display:"flex", width: "100%",marginTop:"30px"}}>
                      <table style={{width:"100%",borderCollapse:"collapse"}}>
                        <thead>
                          <tr className={classes.trheader}>
                            <td rowSpan={2} className={classes.trheadertd} style={{textAlign:"left"}}>Card Types</td>
                            <td colSpan={3} className={classes.trheadertd}>Sales Total</td>
                            <td rowSpan={2} className={classes.trheadertd}>Refund Total</td>
                            <td rowSpan={2} className={classes.trheadertd}>Amount Collected</td>
                          </tr>
                          <tr className={classes.trheader}>
                            <td style={{width:"15%"}} className={classes.trheadertd}>Payment Total</td>
                            <td style={{width:"15%"}} className={classes.trheadertd}>Tips</td>
                            <td style={{width:"15%"}} className={classes.trheadertd}>Service Charges</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className={classes.trodd}>
                            <td style={{textAlign:"left"}} className={classes.trtd} >Visa</td>
                            <td className={classes.trtd} >$31,971.06</td>
                            <td className={classes.trtd} >$617.88</td>
                            <td className={classes.trtd} >$5,204.53</td>
                            <td className={classes.trtd} >$0.00</td>
                            <td className={classes.trtd} >$37,793.47</td>
                          </tr>
                          <tr className={classes.treven}>
                            <td style={{textAlign:"left"}} className={classes.trtd} >American Express</td>
                            <td className={classes.trtd} >$25,335.48</td>
                            <td className={classes.trtd} >$356.22</td>
                            <td className={classes.trtd} >$4,166.17</td>
                            <td className={classes.trtd} >$0.00</td>
                            <td className={classes.trtd} >$29,857.87</td>
                          </tr>
                          <tr className={classes.trodd}>
                            <td style={{textAlign:"left"}} className={classes.trtd} >MasterCard</td>
                            <td className={classes.trtd} >$11,149.94</td>
                            <td className={classes.trtd} >$86.14</td>
                            <td className={classes.trtd} >$1,802.61</td>
                            <td className={classes.trtd} >-$193.50</td>
                            <td className={classes.trtd} >$12,873.05</td>
                          </tr>
                          <tr className={classes.treven}>
                            <td style={{textAlign:"left"}} className={classes.trtd} >Discover</td>
                            <td className={classes.trtd} >$283.17</td>
                            <td className={classes.trtd} >$0.00</td>
                            <td className={classes.trtd} >$47.63</td>
                            <td className={classes.trtd} >$0.00</td>
                            <td className={classes.trtd} >$330.80</td>
                          </tr>
                          <tr style={{borderTop:"1px solid gray"}} className={classes.trodd}>
                            <td style={{textAlign:"left"}} className={classes.trtd} ><b>Total</b></td>
                            <td className={classes.trtd} ><b>$68,739.65</b></td>
                            <td className={classes.trtd} ><b>$1,060.24</b></td>
                            <td className={classes.trtd} ><b>$11,220.94</b></td>
                            <td className={classes.trtd} ><b>-$193.50</b></td>
                            <td className={classes.trtd} ><b>$80,855.19</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </GridContainer>
              </CardBody>
            </Card>
            </div>

            <div style={{width:"100%"}}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      {/* 
                        * Display cash deposits full report
                      */}
                      <div style={{fontSize:"30px",marginTop:"20px",marginLeft:"20px"}}>Cash Deposits</div>
                        <div className="divSummary" style={{ display:"flex", width: "100%",marginTop:"30px"}}>
                          <table style={{width:"100%",borderCollapse:"collapse"}}>
                            <thead>
                              <tr className={classes.trheader}>
                                <td className={classes.trheadertd} style={{textAlign:"left"}}>On Device</td>
                                <td className={classes.trheadertd}>Cash Sales</td>
                                <td className={classes.trheadertd}>Cash Refund</td>
                                <td className={classes.trheadertd}>Cash Adjustment</td>
                                <td className={classes.trheadertd}>Cash Back</td>
                                <td className={classes.trheadertd}>Cash Collected</td>
                                <td className={classes.trheadertd}>Tips Payout</td>
                                <td className={classes.trheadertd}>Expected Deposit</td>
                              </tr>
                            </thead>
                            <tbody>
                                <tr className={classes.trodd}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}>DeviceId</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>-$162.15</td>
                                  <td className={classes.trtd}>$1,370.24</td>
                                </tr>
                                <tr className={classes.treven}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}>DeviceId</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>-$162.15</td>
                                  <td className={classes.trtd}>$1,370.24</td>
                                </tr>
                                <tr className={classes.trodd}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}>DeviceId</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>-$162.15</td>
                                  <td className={classes.trtd}>$1,370.24</td>
                                </tr>
                                <tr className={classes.treven}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}>DeviceId</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>-$162.15</td>
                                  <td className={classes.trtd}>$1,370.24</td>
                                </tr>
                                <tr className={classes.trodd}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}>DeviceId</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>-$162.15</td>
                                  <td className={classes.trtd}>$1,370.24</td>
                                </tr>
                                <tr className={classes.treven}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}>DeviceId</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$0.00</td>
                                  <td className={classes.trtd}>$1,532.39</td>
                                  <td className={classes.trtd}>-$162.15</td>
                                  <td className={classes.trtd}>$1,370.24</td>
                                </tr>
                                <tr className={classes.trodd} style={{borderTop:"1px solid gray"}}>
                                  <td style={{textAlign:"left"}} className={classes.trtd}><b>Total</b></td>
                                  <td className={classes.trtd}><b>$1,532.39</b></td>
                                  <td className={classes.trtd}><b>$0.00</b></td>
                                  <td className={classes.trtd}><b>$0.00</b></td>
                                  <td className={classes.trtd}><b>$0.00</b></td>
                                  <td className={classes.trtd}><b>$1,532.39</b></td>
                                  <td className={classes.trtd}><b>-$162.15</b></td>
                                  <td className={classes.trtd}><b>$1,370.24</b></td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                    </GridContainer>
                  </CardBody>
                </Card>
            </div>

            {/* <div style={{width:"100%"}}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <div style={{fontSize:"30px",marginTop:"20px",marginLeft:"20px"}}>Cash Adjustments</div>
                    </GridContainer>
                  </CardBody>
                </Card>
            </div> */}

        </div>
      
    </GridContainer>
  );
}
