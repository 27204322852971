/*
  @page taxes report
*/

//Import require libraries
import React,{ useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// import DateTimePicker from '@mui/lab/DateTimePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';
import {CSVLink, CSVDownload} from 'react-csv';
import CircularProgress from '@material-ui/core/CircularProgress';
/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox:{
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      width:"100%",
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  tableheaders:{
    width:"126px",
    backgroundColor: '#E5EFFF',
    height:"40px",
    border:"1.5px solid white"
  },
  oddtrtd:{
    height:"40px",
    backgroundColor:"#f3f4f5"
  },
  eventrtd:{
    height:"40px",
  },
  totaltrtd:{
    height:"40px",
    backgroundColor:"#dee0e1"
  },
  
  buttonSearch:{
    width: "100%",
    backgroundColor: '#ffa726', 
    color: '#FFFFFF', 
    padding: "14px 50px",
    "&:hover": {
      backgroundColor: "#ffa726 !important",
      color:"white !important"
    }
  }, 
  startDateBtn :{   
    width: "100% !important",
    paddingLeft:"1%",
    '& div:nth-child(1)':{
      width: "100% !important",
    }     
}
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function taxes report page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = React.useState('');
  const [selectEmployee, setEmployee] = React.useState(0);
  const [selectDevice, setDevice] = React.useState(0);
  const [empDropDownVal, setEmpDropDownVal] = useState([]);
  const [devicesDropDownVal, setDevicesDropDownVal] = useState([]);
  const [reportFilter, setReportFilter] = useState({"emp":0,"device":0});
  const [reportStartDate, setReportStartDate] = React.useState();
  const [totalApplicableSales, setTotalApplicableSales] = React.useState(0);
  const [totalCollectedAmt, settTotalCollectedAmt] = React.useState(0);
  const [totalReturnAmt, setTotalReturnAmt] = React.useState(0);
  const [totalNetAmt, setTotalNetAmt] = React.useState(0);
  const [csvData, setCsvData] = useState([]);
  const [accessPage, setAccessPage] = useState(1); 
  useEffect(() => {
     

 const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_reports === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }	

    getReportFilterData();
  }, []);

  
/*
  @function filtered data page logic
*/
const getReportFilterData = () =>
{
  const data = {};
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
  }    
  data.parent_id = localStorage.getItem('parent_id');
  data.location_id = localStorage.getItem('location_id');
  axios.post(window.apiUrl+'get_report_filter_data', data, {
    headers: headers
  }).then(response => {      
      setEmpDropDownVal(response.data.emp);
      setDevicesDropDownVal(response.data.devices);
      setReportStartDate(response.data.report_start_time.split(":"));
  }).catch(error => {
    
    return error;
  });

}
 
/*
  @function get report data
*/
const getReportData = (data) => {   
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }    
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl+'taxes_report', data, {
      headers: headers
    }).then(response => {
      setTableData(response.data.tableData);
      setTotalApplicableSales(response.data.totalApplicableSales);  
      settTotalCollectedAmt(response.data.totalCollectedAmt);
      setTotalReturnAmt(response.data.totalReturnAmt);
      setTotalNetAmt(response.data.totalNetAmt);

      const exportArr = [];
      exportArr.push(["Taxes Report"]);
      exportArr.push([moment(startDate).format('MMM DD,YYYY hh:mm a')+" - "+moment(endDate).format('MMM DD YYYY hh:mm a')]);
      // exportArr.push(["Filters:"]);      
      exportArr.push([""]);      
      exportArr.push(["Tax rate or flat fee","Applicable sales","Amount collected","Amount refunded","Net amount"]);

      response.data.tableData.map((eachTax, id) => {        
        exportArr.push([eachTax.name,eachTax.applicable_sales,eachTax.collected_amt,eachTax.return_amt,eachTax.net_amt]);        
      });
      exportArr.push(["Total",response.data.totalApplicableSales,response.data.totalCollectedAmt,response.data.totalReturnAmt,response.data.totalNetAmt]);
      setCsvData(exportArr);

    }).catch(error => {
      
      return error;
    });
  }

/*
  @Const get employee filtered data
*/
  const employeeChange = (event) => {
    setEmployee(event.target.value);
    let filterData = reportFilter;
    filterData["emp"] = event.target.value;
    setReportFilter(filterData);
  };
  /*
    @Const get device filtered data
  */
  const deviceChange = (event) => {
    setDevice(event.target.value);
    let filterData = reportFilter;
    filterData["device"] = event.target.value;
    setReportFilter(filterData);
  };
  
  /*
    @function filter using date
  */
  const dateChange = (event) => {
    let selected_date_range = event.target.value;

    switch (selected_date_range) {
      case 1: //Get current day data        
        setStartDate(new Date().setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        setEndDate(new Date());
        break;
      case 2: //Get yesterday data 
        let Yesterday_date = new Date();
        Yesterday_date.setDate(Yesterday_date.getDate() - 1);
        setStartDate(Yesterday_date.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));        
        Yesterday_date.setHours(23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59);
        setEndDate(Yesterday_date);
        break;
      case 3: //Get current week data 
        let this_week = new Date();
        this_week.setDate(this_week.getDate() - this_week.getDay());
        setEndDate(new Date());
        setStartDate(this_week.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 4: //Get last week data 
        let last_week_start_date = new Date();
        let last_week_end_date = new Date();
        last_week_start_date.setDate(last_week_start_date.getDate() - last_week_start_date.getDay() - 7);
        last_week_start_date.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0);
        last_week_end_date.setDate(last_week_end_date.getDate() - last_week_end_date.getDay() - 1);
        last_week_end_date.setHours(23 +  parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59)
        setEndDate(last_week_end_date);
        setStartDate(last_week_start_date);
        break;
      case 5: //Get last 7 days data 
        let last_7_day = new Date();
        last_7_day.setDate(last_7_day.getDate() - 6);
        setEndDate(new Date());
        setStartDate(last_7_day.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 6: //Get current month data 
        let this_month = new Date();
        var firstDay = new Date(this_month.getFullYear(), this_month.getMonth(), 1);
        var lastDay = new Date(this_month.getFullYear(), this_month.getMonth() + 1, 0);

        setEndDate(new Date());
        setStartDate(new Date(this_month.getFullYear(), this_month.getMonth(), 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 7: //Get last month data 
        let last_month = new Date();
        setEndDate(new Date(last_month.getFullYear(), last_month.getMonth(), 0,23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59));
        setStartDate(new Date(last_month.getFullYear(), last_month.getMonth() - 1, 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 8: //Get last 30 days data 
        let last_30_day = new Date();
        last_30_day.setDate(last_30_day.getDate() - 29)
        setEndDate(new Date());
        setStartDate(last_30_day.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 9: //Get last 3 months data
        let last_3_month = new Date();
        last_3_month.setMonth(last_3_month.getMonth() - 3)
        setEndDate(new Date());
        setStartDate(last_3_month.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));
        break;
      case 10: //Get current year data 
          let this_year = new Date();                              
          this_year = new Date(this_year.getFullYear(), 0, 1);
          setStartDate(this_year.setHours(reportStartDate[0],reportStartDate[1],reportStartDate[2],0));  
          setEndDate(new Date());
      break;
      case 11: //Get last year data 
          let lastyear = new Date(new Date().getFullYear() - 1, 0, 1);          
          setEndDate(new Date(lastyear.getFullYear(), 11, 31,23+ parseInt(reportStartDate[0]),59+ parseInt(reportStartDate[1]),59,59));
          setStartDate(new Date(lastyear.getFullYear(), 0, 1,reportStartDate[0],reportStartDate[1],reportStartDate[2],0));  
      break;
      case 12:          
          setEndDate("");
          setStartDate("");  
      break;   
      default:
        break;
    }    
    setDateRange(event.target.value);
  };

  /*
    @Const search filtered data
  */
  const handleSearch = () => {
    const data = { 'start_date': moment(startDate).format('YYYY-MM-DD HH:mm:00'), 'end_date': moment(endDate).format('YYYY-MM-DD HH:mm:59'),'filterData':reportFilter };    
    getReportData(data);
   
  }

  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Reporting - Taxes</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>Reporting - Taxes</h4>
          <p className={classes.cardCategoryWhite}></p>
        </CardHeader>
        <CardBody>
              <center>
                <h3>Fetching Data....</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);
  }
  return (
    <GridContainer>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Reporting - Taxes</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          {/* 
            * Filter options
          */}
          <CardBody>
          <GridContainer>
            <GridItem id="gidaterange" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Date Range</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={dateRange}
                  label="Date Range"
                  onChange={dateChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Yesterday</MenuItem>
                  <MenuItem value={3}>This Week</MenuItem>
                  <MenuItem value={4}>Last Week</MenuItem>
                  <MenuItem value={5}>Last 7 Days</MenuItem>
                  <MenuItem value={6}>This Month</MenuItem>
                  <MenuItem value={7}>Last Month</MenuItem>
                  <MenuItem value={8}>Last 30 Days</MenuItem>
                  <MenuItem value={9}>Last 3 Months</MenuItem>
                  <MenuItem value={10}>This Year</MenuItem>
                  <MenuItem value={11}>Last Year</MenuItem>
                  <MenuItem value={12}>Custom Date Range</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem id="giemployee" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Employees</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={selectEmployee}
                  label="Employees"
                  onChange={employeeChange}
                >
                  <MenuItem value={0}>All Employees</MenuItem>
                  {empDropDownVal.map((eachEmp, id) => {
                          return <MenuItem value={eachEmp.user_id}>{eachEmp.employee_name}</MenuItem>
                      })
                    }  
                  
                </Select>
              </FormControl>
            </GridItem>
            <GridItem id="gidevice" xs={12} sm={12} md={4} style={{"marginTop":"16px"}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Devices</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="chart"
                  value={selectDevice}
                  label="Devices"
                  onChange={deviceChange}
                >
                  <MenuItem value={0}>All Devices</MenuItem>
                  {devicesDropDownVal.map((eachDevice, id) => {
                          return <MenuItem value={eachDevice.serial_number}>{eachDevice.serial_number} ({eachDevice.nick_name})&nbsp; {(parseInt(eachDevice.is_approve) === 0)? <i> - Inactive</i>:""}</MenuItem>
                      })
                    }                  
                </Select>
              </FormControl>
            </GridItem>                
          </GridContainer>
          <GridContainer>
                <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}} >
                <FormControl sx={{ m: 1, width: "100%" }}>
                  
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker   
                               
                  label="Start Date"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                    
                  />
                  </LocalizationProvider>
                  </FormControl>
                </GridItem>                
                <GridItem xs={12} sm={12} md={4} id="startdateandtime"  style={{marginTop:"20px"}}>
                <FormControl sx={{ m: 1, width: "100%" }}>           
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker        
                  label="End Date"        
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  </LocalizationProvider>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}  style={{marginTop:"20px"}}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <Button id="btnsearch" variant="contained" color="primary" onClick={handleSearch} className={`${classes.button} ${classes.buttonSearch}`}>
                    Search
                  </Button>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}  style={{marginTop:"20px"}}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <CSVLink data={csvData} color="primary" >
                  <Button id="btnGreenAdd" variant="contained" color="primary"  className={`${classes.button} ${classes.buttonSearch}`}>
                      Export
                    </Button>
                </CSVLink>
                   </FormControl>
                  </GridItem>
          </GridContainer>
       <br></br><br></br>

          <GridContainer>
            {/* 
              * Display taxes report
            */}
              <div style={{width:"100%"}}>
                  <table style={{width:"100%",borderCollapse:"collapse"}}>
                    <thead>
                      <tr>
                        <th style={{textAlign:"left",paddingLeft:"10px"}} className={classes.tableheaders}>Tax rate or flat fee</th>
                        <th className={classes.tableheaders}>Applicable sales</th>
                        <th className={classes.tableheaders}>Amount collected</th>
                        <th className={classes.tableheaders}>Amount refunded</th>
                        <th className={classes.tableheaders}>Net amount</th>
                      </tr>
                    </thead>
                    <tbody>

                    {
                        tableData.map((eachTax, id) => {
                          return  <tr>
                          <td style={{paddingLeft:"10px"}} className={classes.oddtrtd}><b>{eachTax.name}</b></td>
                          <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.oddtrtd}>${eachTax.applicable_sales}</td>
                          <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.oddtrtd}>${eachTax.collected_amt}</td>
                          <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.oddtrtd}>${eachTax.return_amt}</td>
                          <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.oddtrtd}>${eachTax.net_amt}</td>
                        </tr>;
                        })
                      }                   
                      <tr style={{borderTop:"1px solid darkgray"}}>
                        <td style={{paddingLeft:"10px"}} className={classes.totaltrtd}><b>Total</b></td>
                        <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.totaltrtd}><b>${totalApplicableSales}</b></td>
                        <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.totaltrtd}><b>${totalCollectedAmt}</b></td>
                        <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.totaltrtd}><b>${totalReturnAmt}</b></td>
                        <td style={{textAlign:"right",paddingRight:"20px"}} className={classes.totaltrtd}><b>${totalNetAmt}</b></td>
                      </tr>
                    </tbody>
                  </table>
              </div>
          </GridContainer>
            
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
